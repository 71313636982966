import * as React from 'react'
import styled from '@emotion/styled'

import Page from '../templates/Page'
import Seo from '../components/seo'
import { colors, space } from '../utils/theme'
import { LeftTwoThirds, Container, Text, Hero, H2, H3 } from '../utils/styles'

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const Impressum = () => {
  return (
    <Page useContainer={false}>
      <Seo title="Impressum" />

      <Container>
      <Hero>Impressum</Hero>

        <H2 style={{ marginTop: space[20] }}>Angaben gemäss § 5 TMG</H2>
        <Text style={{ marginBottom: space[20] }}>
        Karl Herga
        Konstruktionsbüro HERGA
        Steilstraße 1a
        90513 Zirndorf
        </Text>
        <H2 style={{ marginTop: space[20] }}>Kontakt</H2>
        <Text style={{ marginBottom: space[20] }}>
        E-Mail:	info@konstruktionsbuero-herga.de
        Telefon:	0911 / 60 49 88
        Telefax:	0911 / 60 79 34
        <br />
        </Text>
        <H2 style={{ marginTop: space[20] }}>Geschäftsangaben</H2>
        <H3 style={{ marginTop: space[10] }}>Umsatzsteuer-Identifikationsnummer:</H3>
        <Text style={{ marginBottom: space[20] }}>
         DE 132 685 176
        </Text>
        <H2 style={{ marginTop: space[20] }}>Haftungsausschluss</H2>
        <H3 style={{ marginTop: space[10] }}>Haftung für Inhalte</H3>
        <Text style={{ marginBottom: space[20] }}>
        Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. 
        Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte 
        können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind 
        wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den 
        allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als 
        Diensteanbieter jedoch nicht verpflichtet, übermittelte oder 
        gespeicherte fremde Informationen zu überwachen oder nach Umständen 
        zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen 
        zur Entfernung oder Sperrung der Nutzung von Informationen nach den 
        allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche 
        Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten 
        Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden 
        Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
        <br />
        </Text>
        <H3 style={{ marginTop: space[10] }}>Haftung für Links</H3>
        <Text style={{ marginBottom: space[20] }}>
        Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte 
        wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte 
        auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist 
        stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. 
        Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche 
        Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der 
        Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der 
        verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer 
        Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen 
        werden wir derartige Links umgehend entfernen.
        <br />
        </Text>
        <H3 style={{ marginTop: space[10] }}>Urheberrecht</H3>
        <Text style={{ marginBottom: space[20] }}>
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen 
        Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, 
        Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der 
        Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des 
        jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite 
        sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. 
        Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, 
        werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte 
        Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine 
        Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden 
        Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige 
        Inhalte umgehend entfernen.
        <br />
        </Text>
        <H3 style={{ marginTop: space[10] }}>Datenschutz</H3>
        <Text style={{ marginBottom: space[20] }}>
        Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener 
        Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise 
        Name, Anschrift oder eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, 
        stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche 
        Zustimmung nicht an Dritte weitergegeben. Wir weisen darauf hin, dass 
        die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) 
        Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem 
        Zugriff durch Dritte ist nicht möglich. Der Nutzung von im Rahmen der 
        Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur 
        Übersendung von nicht ausdrücklich angeforderter Werbung und 
        Informationsmaterialien wird hiermit ausdrücklich widersprochen. 
        Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte 
        im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.
        <br />
        </Text>
        <H3 style={{ marginTop: space[10] }}>Google Analytics</H3>
        <Text style={{ marginBottom: space[20] }}>
        Diese Website benutzt Google Analytics, einen Webanalysedienst 
        der Google Inc. (''Google''). Google Analytics verwendet sog. ''Cookies'', 
        Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse 
        der Benutzung der Website durch Sie ermöglicht. Die durch den Cookie erzeugten 
        Informationen über Ihre Benutzung diese Website (einschließlich Ihrer IP-Adresse) 
        wird an einen Server von Google in den USA übertragen und dort gespeichert. 
        Google wird diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, 
        um Reports über die Websiteaktivitäten für die Websitebetreiber zusammenzustellen 
        und um weitere mit der Websitenutzung und der Internetnutzung verbundene 
        Dienstleistungen zu erbringen. Auch wird Google diese Informationen 
        gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben 
        oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Google 
        wird in keinem Fall Ihre IP-Adresse mit anderen Daten der Google in Verbindung 
        bringen. Sie können die Installation der Cookies durch eine entsprechende 
        Einstellung Ihrer Browser Software verhindern; wir weisen Sie jedoch darauf 
        hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser 
        Website voll umfänglich nutzen können. Durch die Nutzung dieser Website erklären 
        Sie sich mit der Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor 
        beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden.
        <br />
        </Text>
      </Container>
    </Page>
  )
}

export default Impressum
